import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import CreateIcon from "../../assets/images/create_icon.svg";
import {
  updateCashBackRules,
  getCashbackRules,
} from "../../services/cashback.service";
import { useParams } from "react-router-dom";
import CloseIcon from "../../assets/images/close_icon.svg";
import UploadImg from "../../assets/images/upload_img.svg";
import { fileUpload } from "../../services/common.service";
import { GetMerchantCategory } from "../../services/Category.service";
import TextEditor from "../TextEditor/TextEditor";
import { calculatePercentage } from "../../utils/helpers/helper";
import { Parser } from "htmlparser2";
import { DomHandler } from "domhandler";
import {
  latitudeRegex,
  longitudeRegex,
  phoneRegex,
  VatIdRegex,
  Swiftcode,
  IBANcode,
} from "../../utils/Regex";
import { selectcountry } from "../../store/countrylist/country.selector";
import { useDispatch, useSelector } from "react-redux";
import { setcountry } from "../../store/countrylist/country.reducer";
import { Counrtylist } from "../../services/merchant.service";

import {
  addBrandBank,
  getBrandBankList,
  bankMasterList,
  setPrimaryAccount,
  removeBank,
  updatebankaccount
} from "../../services/Bank.service";

const fileType = "Cashback";
function Editbank({ brand, editdata = {}, back }) {
  console.log('back::: ', back);
  console.log("editdata::: ", editdata);

  const deserialize = (html) => {
    const parseNode = (node) => {
      if (node.type === "text") {
        let attributes = {};
        if (
          node.parent &&
          (node.parent.name === "strong" ||
            node.parent.name === "em" ||
            node.parent.name === "u")
        ) {
          if (node.parent.name === "strong") attributes.bold = true;
          if (node.parent.name === "em") attributes.italic = true;
          if (node.parent.name === "u") attributes.underline = true;
        }
        return { ...attributes, text: node.data };
      }

      switch (node.name) {
        case "p":
          return {
            type: "paragraph",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "a":
          return {
            type: "link",
            url: node.attribs.href,
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "blockquote":
          // Assuming all block quotes are wrapped in a paragraph tag
          return {
            type: "block-quote",
            children: [
              {
                type: "paragraph",
                children: node.children ? node.children.map(parseNode) : [],
              },
            ],
          };
        case "ul":
          return {
            type: "bulleted-list",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "ol":
          return {
            type: "numbered-list",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "li":
          return {
            type: "list-item",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "h1":
          return {
            type: "heading-one",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "h2":
          return {
            type: "heading-two",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "div":
          // Assuming divs are used for text alignment
          const align = node.attribs.style.split(":")[1].trim();
          return {
            align,
            children: node.children ? node.children.map(parseNode) : [],
          };
        default:
          // Check if the node has only one child
          if (node.children && node.children.length === 1) {
            return parseNode(node.children[0]);
          } else {
            return node.children ? node.children.map(parseNode) : [];
          }
      }
    };

    try {
      const handler = new DomHandler();
      const parser = new Parser(handler);

      parser.write(html || "");
      parser.end();

      const document = handler.dom.map(parseNode);
      return document.length > 0
        ? document
        : [{ type: "paragraph", children: [{ text: "" }] }];
    } catch (error) {
      console.error("Error occurred during deserialization:", error);
      return [
        {
          type: "paragraph",
          children: [{ text: "Error occurred during deserialization" }],
        },
      ];
    }
  };

  const [privacyPolicy, setPrivacyPolicy] = useState(
    brand?.privacy_policy || ""
  );
  const [termsAndCondition, setTermsAndCondition] = useState(
    brand?.terms_condition || ""
  );
  const [stepsToRedeem, setStepsToRedeem] = useState(
    brand?.step_to_redeem || ""
  );

  // const user = useSelector(selectCurrentUser)?.userDetail;

  const { id } = useParams();
  const [categoryList, setCategoryList] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  const formattedToday = new Date().toISOString().split("T")[0];
  // const [isMultipleRedemption, setIsMultipleRedemption] = useState(false);

  const initialRedemptionType = brand?.redemption_type === "multirredemption";

  const [isMultipleRedemption, setIsMultipleRedemption] = useState(
    initialRedemptionType
  );

  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;

    setFieldValue(
      "redemption_type",
      isChecked ? "multirredemption" : "perredemption"
    );
    setIsMultipleRedemption(isChecked);
    if (!isChecked) setFieldValue("redemption_count", 1);
  };

  const countries = useSelector(selectcountry);
  const dispatch = useDispatch();
  // const { id } = useParams();
  const [bankMaster, setBankMaster] = useState([]);

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues: {
      //   rules_name: brand?.rules_name,

      //   cash_back_on: brand?.cash_back_on,
      // //   startDate: new Date(brand?.start_date).toISOString().split("T")[0],
      // //   endDate: new Date(brand?.end_date).toISOString().split("T")[0],
      //   offer_type: brand?.offer_type,
      //   offer_value: brand?.offer_value,
      //   min_spend: brand?.min_spend,
      //   upper_limit: brand?.upper_limit,
      //   logo: brand?.campaign_logo,
      //   banner: brand?.banner_image,
      //   selectedLogo: brand?.campaign_logo,
      //   privacyPolicy: brand?.privacy_policy,
      //   termsAndCondition: brand?.terms_condition,
      //   privacyPolicy_raw: brand?.privacy_policy_raw,
      //   termsAndCondition_raw: brand?.terms_condition_raw,
      //   stepsToRedeem_raw: brand?.step_to_redeem_raw,
      //   stepsToRedeem: brand?.step_to_redeem,
      //   allow_coupon: brand?.allow_coupon,
      //   allow_voucher: brand?.allow_voucher,
      //   category_id: brand?.category_id,
      //   redemption_count: brand?.redemption_count,
      //   redemption_type: brand?.redemption_type,
      name: editdata?.name,
      streetAddress: null,
      city: null,
      state: null,
      accountNumber: null,
      iban: null,
      swiftCode: null,
      bankName: null,
      branchName: null,
      country: null,
      Create_type: null,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      rules_name: Yup.string()
        .matches(
          /^[a-zA-Z0-9\s]*$/,
          "Only alphanumeric characters and spaces are allowed"
        )
        .max(50, "Rule Name must be at most 50 characters")
        .required("Rule Name is required"),
      cash_back_on: Yup.string().required("Coupon Count is required"),
      category_id: Yup.string().required("Category is required"),
      startDate: Yup.string()
        .test(
          "is-greater-or-equal-today",
          "startDate must be greater than or equal to today's date",
          function (value) {
            if (!value) return false;
            return new Date(value) >= new Date(formattedToday);
          }
        )
        .required("startDate is required"),
      endDate: Yup.string().required("endDate is required"),
      offer_type: Yup.string().required("offer type is required"),
      offer_value: Yup.string()
        .test({
          name: "offer_value",
          exclusive: false,
          test: function (value, { createError }) {
            const isNumeric = /^[1-9][0-9]*([.][0-9]+)?$|^0[.][0-9]+$/.test(
              value
            );
            if (isNumeric) {
              if (this.parent.offer_type === "Percentage") {
                if (parseFloat(value) < 100) return true; // Reset error
                else
                  return createError({
                    message:
                      "Value must be a number and below 100 for percentage offers",
                  });
              } else {
                if (parseFloat(value) < parseFloat(this.parent.min_spend))
                  return true; // Reset error
                else
                  return createError({
                    message: "Value must be a number and below min spend",
                  });
              }
            } else {
              return true; // Reset error for non-numeric values
            }
          },
        })
        .matches(
          /^\d+(\.\d{1,3})?$/,
          "Min spend must be a positive number with up to three decimal places"
        )
        .required("Value is required"),
      min_spend: Yup.string()
        .required("Min spend  is required")
        .matches(
          /^\d+(\.\d{1,3})?$/,
          "Min spend must be a positive number with up to three decimal places"
        ),
      upper_limit: Yup.string()
        .matches(
          /^\d+(\.\d{1,3})?$/,
          "Upper limit must be a positive number with up to three decimal places"
        )
        .test({
          name: "upper_limit",
          exclusive: false,
          test: function (value, { parent }) {
            // Check if the value is a valid number
            const isNumeric = /^[1-9][0-9]*([.][0-9]+)?$|^0[.][0-9]+$/.test(
              value
            );

            // Skip validation for "Value" offer type or empty value
            if (parent.offer_type === "Value" || !+value) {
              return true;
            }

            // Return false if value is not numeric
            if (!isNumeric) {
              return false;
            }

            // Specific validation for "Percentage" offer type (value must be positive)
            if (parent.offer_type === "Percentage") {
              return parseFloat(value) > 0;
            }

            // Skip validation for other offer types
            return true;
          },
        }),
      redemption_count:
        isMultipleRedemption &&
        Yup.number()
          .typeError("Value must be a number")
          .positive("Count must be greater than 0")
          .integer("Count must be an integer"),
      privacyPolicy: Yup.string()
        .max(800, "Privacy Policy  must be at most 800 characters")
        .required("Privacy Policyis required"),
      termsAndCondition: Yup.string()
        .max(800, "Terms and Condition  must be at most 800 characters")
        .required("Terms and Condition is required"),
      stepsToRedeem: Yup.string()
        .max(800, "Steps To Redeem  must be at most 800 characters")
        .required("Steps To Redeem required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const payload = {
          ...values,
          brand_id: id,
          rules_id: brand.id,
          min_spend: +values.min_spend,
          upper_limit:
            values?.offer_type === "Percentage" ? +values?.upper_limit : 0,
          discount_value:
            values?.offer_type === "Percentage"
              ? +values?.upper_limit ||
                calculatePercentage(+values?.offer_value, +values.min_spend)
              : +values?.offer_value,
          category_id: +values?.category_id,
          privacyPolicy_raw: deserialize(values?.privacyPolicy),
          termsAndCondition_raw: deserialize(values?.termsAndCondition),
          stepsToRedeem_raw: deserialize(values?.stepsToRedeem),
          redemption_count:
            values?.redemption_type == "perredemption"
              ? 1
              : +values?.redemption_count,
          redemption_type: values.redemption_type,
        };

        const res = await updateCashBackRules(payload);
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          resetForm();
          back();
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    },
  });

  const formikedit = useFormik({
    initialValues: {
     
      id:editdata?.id,
      name: editdata?.name,
      streetAddress:editdata?.street_address,
      city: editdata?.city,
      state: editdata?.state,
      accountNumber: editdata?.account_number,
      iban: editdata?.iban_number,
      swiftCode: editdata?.swift_code,
      bankName: editdata?.bank_name,
      branchName: editdata?.branch_name,
      country:editdata?.country,
      account_id:editdata?.id,
      user_id :editdata?.cus_id_mer_id
      // Create_type: editdata?.name,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Contact name is required")
        .matches(
          /^[a-zA-Z0-9\s]*$/,
          "Only alphanumeric characters and spaces are allowed"
        ),
      streetAddress: Yup.string()
        .required(" Street address is required"),
        // .matches(
        //   /^[a-zA-Z0-9\s,/-]+$/,
        //   "Only alphanumeric characters, spaces, commas, hyphens, and slashes are allowed"
        // ),
      city: Yup.string()
        .required(" City is required"),
        // .matches(
        //   /^[a-zA-Z0-9\/,\s-]+$/,
        //   "City Name must contain only letters, numbers, slashes, hyphens, and commas"
        // ),
      state: Yup.string()
        .required(" State is required"),
        // .matches(
        //   /^[a-zA-Z0-9\s]*$/,
        //   "Only alphanumeric characters and spaces are allowed"
        // ),
      accountNumber: Yup.string()
        .required(" Account number is required")
        .matches(phoneRegex, "Account  number is not valid"),
      iban: Yup.string()
        .required(" Iban is required")
        .matches(IBANcode, "Iban number is not valid"),
      swiftCode: Yup.string()
        .required(" Swift code is required")
        .matches(Swiftcode, "Swiftcode number is not valid"),
      bankName: Yup.string().required(" Bank name is required"),
      branchName: Yup.string()
        .required(" Branch  name is required"),
        // .matches(
        //   /^[a-zA-Z0-9\s]*$/,
        //   "Only alphanumeric characters and spaces are allowed"
        // ),
      country: Yup.string().required(" Country is required"),
    }),
    onSubmit: async (values) => {
      console.log('values::: ', values);
      // values.store_count = +values.store_count
      // try {
      //   const res = await updatelocation(values);
      //   console.log("res::: ", res);
      //   if (res?.status === 200 && res?.data?.statusCode === 200) {
      //     toast.success(res.data.message);
      //     formikedit.resetForm();
      //     formikedit.setErrors({});
      //     if (role == "admin" || role == "superadmin") {
      //       back();
      //     } else {
      //       navigate(location.pathname, { replace: true });
      //     }
      //     // getMerchantDetail()
      //   } else {
      //     toast.error(res?.message || res?.data?.message);
      //   }
      // } catch (error) {
      //   if (error?.message) {
      //     const msg = error?.message?.split(":")?.[1]?.trim();
      //     console.error(msg);
      //   }
      //   console.log(error.message);
      // }
      try {
        const payload = {
          ...values,
       
        }
     

        const res = await updatebankaccount(payload)
        console.log('res::: ', res);
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message)
          resetForm();
          back()
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg)
        }
        console.log(error.message);
      }
    },
  });

  console.log("test",formikedit.errors)

  const handleFileChange = async (event, type = "logo") => {
    try {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        let res = await fileUpload(formData, fileType);
        if (res?.data?.statusCode === 200) {
          if (type === "banner") {
            setFieldValue("banner", res?.data?.data);
          } else {
            setFieldValue("logo", res?.data?.data);
            setFieldValue("selectedLogo", URL.createObjectURL(selectedFile));
          }
        }
      }
    } catch (error) {}
  };

  const removeLogo = (type = "logo") => {
    if (type === "banner") {
      setFieldValue("banner", "");
    } else {
      setFieldValue("logo", "");
      setFieldValue("selectedLogo", "");
    }
  };

  const Cashbackoption = [
    { key: "Product", value: "Product" },
    { key: "Cart", value: "Cart" },
    { key: "Promotions", value: "Promotions" },
  ];

  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setFieldValue("offer_value", "");
  };
  useEffect(() => {
    const getCountry = async () => {
      try {
        const res = await Counrtylist();
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          dispatch(setcountry(res?.data?.data));
          // setBrand(res?.data?.getallbrand || []);
        } else {
          // toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };
    const getbankMaster = async () => {
      try {
        const res = await bankMasterList();
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          setBankMaster(res?.data?.data);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };

    getCountry();
    getbankMaster();
  }, []);

  return (
    <>

      <form onSubmit={formikedit.handleSubmit}>
        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="email"
          >
            Contact Name
          </label>
          <input
            className="MainInput"
            type="text"
            placeholder="Enter Contact Name"
            id="name"
            name="name"
            value={formikedit.values.name}
            onChange={formikedit.handleChange}
            onBlur={formikedit.handleBlur}
          />
          {formikedit.errors.name && formikedit.touched.name ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {formikedit.errors.name}
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="email"
          >
            Street Address
          </label>
          <input
            className="MainInput"
            type="text"
            placeholder="Enter Street Address"
            id="streetAddress"
            name="streetAddress"
            value={formikedit.values.streetAddress}
            onChange={formikedit.handleChange}
            onBlur={formikedit.handleBlur}
          />
          {formikedit.errors.streetAddress &&
          formikedit.touched.streetAddress ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {formikedit.errors.streetAddress}
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="row">
          <div className="col-md-6 mb_24">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Country
            </label>
            <select
              className="selectMainInput"
              id="country"
              placeholder="country"
              // id="city"
              name="country"
              value={formikedit.values.country}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            >
              <option>---Select Country---</option>
              {countries.map((city, index) => (
                                <option key={index}>{city.name}</option>
                            ))}
            </select>

            {formikedit.errors.country && formikedit.touched.country ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.country}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
                State Name
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder="Enter State Name"
                id="state"
                name="state"
                value={formikedit.values.state}
                onChange={formikedit.handleChange}
                onBlur={formikedit.handleBlur}
              />
              {formikedit.errors.state && formikedit.touched.state ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {formikedit.errors.state}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
                City Name
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder="Enter City Name"
                id="city"
                name="city"
                value={formikedit.values.city}
                onChange={formikedit.handleChange}
                onBlur={formikedit.handleBlur}
              

              />
              {formikedit.errors.city && formikedit.touched.city ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {formikedit.errors.city}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
                Account Number
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder="Enter Account Number"
                id="accountNumber"
                name="accountNumber"
                value={formikedit.values.accountNumber}
                onChange={formikedit.handleChange}
                onBlur={formikedit.handleBlur}
              />
              {formikedit.errors.accountNumber && formikedit.touched.accountNumber ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {formikedit.errors.accountNumber}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
                IBAN
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder="Enter IBAN"
                id="iban"
                name="iban"
                value={formikedit.values.iban}
                onChange={formikedit.handleChange}
                onBlur={formikedit.handleBlur}
              />
              {formikedit.errors.iban && formikedit.touched.iban ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {formikedit.errors.iban}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
                Swift Code
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder="Enter swift Code"
                id="swiftCode"
                name="swiftCode"
                value={formikedit.values.swiftCode}
                onChange={formikedit.handleChange}
                onBlur={formikedit.handleBlur}
              />
              {formikedit.errors.swiftCode && formikedit.touched.swiftCode ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {formikedit.errors.swiftCode}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb_24">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Bank Name
            </label>
            <select
              className="selectMainInput"
              id="bankName"
              placeholder="bankName"
              // id="city"
              name="bankName"
              value={formikedit.values.bankName}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            >
              <option>---Select Bank Name---</option>
              {bankMaster.map((bank, index) => (
                                <option key={index}>{bank.bankname}</option>
                            ))}
            </select>

            {formikedit.errors.bankName && formikedit.touched.bankName ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.bankName}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="col-md-6 mb_24">
            <div>
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_12"
                htmlFor="fn"
              >
                Branch Name
              </label>
              <input
                className="MainInput"
                type="text"
                placeholder="Enter Branch Name"
                id="branchName"
                name="branchName"
                value={formikedit.values.branchName}
                onChange={formikedit.handleChange}
                onBlur={formikedit.handleBlur}
              />
              {formikedit.errors.branchName && formikedit.touched.branchName ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {formikedit.errors.branchName}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
          {/* <div className="col-md-6 mb_24">
                        <label
                            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                            htmlFor="email"
                        >
                            Country
                        </label>
                        <select
                            className="MainInput"
                            id="country"
                            placeholder="country"
                            // id="city"
                            name="country"
                            value={values.country}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        >
                            <option>---Select City---</option>
                            {countries.map((city, index) => (
                                <option key={index}>{city.name}</option>
                            ))}
                        </select>

                        {errors.country && touched.country ? (
                            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                                {errors.country}
                            </p>
                        ) : (
                            <></>
                        )}
                    </div> */}
        </div>

        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap12">
          <img src={CreateIcon} alt="CreateIcon" className="width_20px" />
          <span>Save Bank</span>
        </button>
      </form>
    </>
  );
}

export default Editbank;
