import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { getUserWalletDetails } from "../../services/customer.service";
import { getcardlist } from "../../services/campaign.service";

import { formatCurrency } from "../../utils/helpers/helper";
import PaginatedTable from "../common/PaginatedTable";
import CardchipIcon from "../../assets/images/cardChip_icon.svg";
import MasterCardIcon from "../../assets/images/masterCard_icon.svg";

const CustomerCard = () => {
  const { id, name } = useParams();
  console.log("name::: ", name);
  console.log("id::: ", id);
  const [walletData, setWalletData] = useState();
  console.log("walletData::: ", walletData);
  // const { id } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const handleTogglePicker = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (ranges) => {
    const temp = {
      startDate: ranges["range1"]["startDate"],
      endDate: ranges["range1"]["endDate"],
    };
    setRange(temp);
  };
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: undefined,
    endDate: new Date(),
  });

  useEffect(() => {
    getWalletData(id);
  }, [id, currentPage, itemsPerPage, searchKey, range]);

  const formatDate = (dateString) => {
    const parts = dateString.split("-");
    const day = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1; // Month is zero-based in Date object
    const year = parseInt(parts[2]);
    return new Date(year, month, day).toLocaleDateString("en-GB");
  };

  const getWalletData = async (id) => {
    try {
      const res = await getcardlist(id);
      console.log("res::: ", res);
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        console.log("res::: ", res);
        setWalletData(res?.data?.data);
        // setTotalCount(res?.data?.data?.count);
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      console.log("error::: ", error);
      console.error("Error fetching wallet data:", error.message);
      toast.error("Failed to fetch wallet data");
    }
  };

  return (
    <div>
      <div className="row">
        {walletData && walletData.length > 0 ? (
          walletData.map((card, index) => (
            <div className="col-md-4" key={index}>
              <div className="cardFrame">
                <div className="displayFlex alignItem_center justifyContent_spacebetween mb_16">
                  <div>
                    <h3 className="fontSize14 fontWeight600 blackClr_text">
                      {card.issuer}
                    </h3>
                  </div>
                  <div>
                    {card.is_primary === true && (
                      <a
                        href="#"
                        className="fontSize14 fontWeight600 cyanSkyClr_text"
                      >
                        Primary
                      </a>
                    )}
                  </div>
                </div>

                <img
                  src={CardchipIcon}
                  alt="CardchipIcon"
                  className="mb_16 width54px"
                />

                <div className="displayFlex alignItem_center justifyContent_spacebetween">
                  <div>
                    <p className="fontSize14 fontWeight500 SteelWoolClr_text">
                      {card.card_number
                        ? `**** **** **** ${card.card_number.slice(-4)}`
                        : "**** **** **** 1234"}
                    </p>

                    <h5 className="fontSize14 fontWeight600 blackClr_text">
                      {card.cardholder_name}
                    </h5>
                  </div>
                  <div>
                    <img src={MasterCardIcon} alt="MasterCardIcon" />
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-md-12">
            <p className="fontSize14 fontWeight600 blackClr_text">
              No cards available. Please add a card to your wallet.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerCard;
