import { KrtiPayService } from './api'

const krtiWebUrl = process.env.REACT_APP_WEB_BASE_URL;


export const superAdminLogin = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/user/signin`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const registerBrand = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/merchant-management/createBrand`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const verifyOtp = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/merchant-management/verifyOtp`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const getBrandList = async (req) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/merchant-management/getAllUsers`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const getDropDownList = async (requestBody) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/merchant-management/getUsersDropDownList`, requestBody)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const getCustomerList = async (req) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/dashboard/getAllUsers`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const getAdminUserList = async (req) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/dashboard/getAllAdminList`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

// add admin

export const createAdmin = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/user/registerUser`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const CreateNewCampaign = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/campaign-mangement/add-campaign
        `, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const EditCampaignDetail = async (req) => {
    try {
        return await KrtiPayService.patch(`${krtiWebUrl}/campaign-mangement/update-Offer
        `, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const updatebrand = async (body) => {
    try {
        return await KrtiPayService.patch(`${krtiWebUrl}/merchant-management/update-brand`, body)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}





export const addoffer = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/offer-mangement/Add-Offer`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}



export const getoffer = async (id,requestBody) => {
    const { offset, limit, user_id, brand_id,search,startDate ,endDate } = requestBody
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/campaign-mangement?id=${id}&offset=${offset}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&search=${search}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const getcampaignlist = async (id,requestBody) => {
    const { offset, limit, user_id, brand_id,search,startDate ,endDate } = requestBody
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/campaign-mangement/get-campaign-users?id=${id}&offset=${offset}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&search=${search}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const getcashbacklist = async (id,requestBody) => {
    const { offset, limit, user_id, brand_id,search,startDate ,endDate } = requestBody
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/campaign-mangement/get-cashback-users?id=${id}&offset=${offset}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&search=${search}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const getcardlist = async (id) => {
    console.log('id::: ', id);
    // const search = praveen
    // const { offset, limit, user_id, brand_id, search,startDate ,endDate } = requestBody
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/campaign-mangement/get-casd-users?id=${id}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const updateoffer = async (body) => {
    try {
        return await KrtiPayService.patch(`${krtiWebUrl}/offer-mangement/update-Offer`, body)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const AdminResetPassword = async (req) => {
    try {
        let payload = {
            old_password: req?.oldPassword,
            new_password: req?.newPassword
        }
        return await KrtiPayService.post(`${krtiWebUrl}/user/reset_password/${req.id}`, payload)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}



export const updateCampaignStatus = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/campaign-mangement/activeCampaign/${req?.id}/${req?.status}
        `)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}



export const deleteCampaign = async (req) => {
    try {
        return await KrtiPayService.delete(`${krtiWebUrl}/campaign-mangement/${req?.id}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}



