import { KrtiPayService } from './api'


const krtiWebUrl = process.env.REACT_APP_WEB_BASE_URL;


export const getCategorylist = async (requestBody) => {
    const { offset, limit, user_id, brand_id, search, startDate, endDate } = requestBody
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/category/fetch-categories/?offset=${offset}&limit=${limit}&search=${search}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const CreateCategory = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/category/add-update-category
        `, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const CategoryStatusupdate = async (req) => {
    // const {i} = payload
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/category/statusUpdate/${req?.id}/${req?.status}/
        `, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const GetMerchantCategory = async (selectedMerchant) => {

    try {
        return await KrtiPayService.get(`${krtiWebUrl}/category/getmerchantCategory/${selectedMerchant}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}