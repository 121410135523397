import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getCustomerList } from "../../services/super-admin.service";
import { useNavigate } from "react-router-dom";
import ViewIcon from "../../assets/images/view_icon.svg";
import PaginatedTable from "../common/PaginatedTable";
import DatePicker from "../DatePicker/DatePicker";
import { exportCustomer } from "../../services/export.service";
import { convertToISOString } from "../../services/common.service";

const Customerpage = () => {
  const navigate = useNavigate();

  const [list, setList] = useState([]);

  // Paginated Table Variables
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: undefined,
    endDate: new Date(),
  });
  const handleExport = async () => {
    try {
      let payload = {
        startDate: convertToISOString(range.startDate),
        endDate: convertToISOString(range.endDate),
        search: searchKey
      };
      await exportCustomer(payload);
    } catch (error) {}
  };

  const [column, setColumn] = useState([
    // {
    //   heading: () => "S.No",
    //   cell: (row, i) => {
    //     return i;
    //   },
    //   hidden: false,
    //   width: "20",
    //   id: 1,
    // },
    {
      heading: () => "Action",
      cell: (row) => (
        <>
          <div>
            <button
              className="emptyBtn"
              onClick={() =>
                navigate(
                  `/admin/customer-detail/${row?.id}/${row?.customer_name}`
                )
              }
            >
              <img src={ViewIcon} alt="ViewIcon" />
            </button>
          </div>
        </>
      ),
      hidden: false,
      // width: "100",
      // align: "center",
      id: 1,
    },
    {
      heading: () => "Customer Id",
      cell: (row) => (row["cus_ref_no"] ? row["cus_ref_no"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },
    {
      heading: () => "Customer Name",
      cell: (row) => (row["customer_name"] ? row["customer_name"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },
    // {
    //   heading: () => "Last Name",
    //   cell: (row) => (row["last_name"] ? row["last_name"] : "-"),
    //   hidden: false,
    //   width: "120",
    //   align: "left",
    //   id: 3,
    // },
    {
      heading: () => "Country",
      cell: (row) => (row["country"] ? row["country"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 4,
    },
    {
      heading: () => "Email",
      cell: (row) => (row["email"] ? row["email"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 5,
    },
    {
      heading: () => "Mobile",
      cell: (row) => (row["mobile"] ? row["mobile"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 6,
    },
    {
      heading: () => "Create Date",
      cell: (row) => (row["created_Date"] ? row["created_Date"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 6,
    },
    {
      heading: () => "Status",
      cell: (row) => (
        <span
          className={
            row?.active_flag
              ? "fontWeight600 UFOGreen_text"
              : "fontWeight600 errorClr_text"
          }
        >
          {row["active_flag"] ? "Active" : "Inactive"}
        </span>
      ),
      hidden: false,
      width: "120",
      align: "left",
      id: 7,
    },
  ]);

  useEffect(() => {
    const customerList = async () => {
      const offset = (currentPage - 1) * itemsPerPage;
      const offsetToUse = offset >= 0 ? offset : 0;

      try {
        const requestBody = {
          offset: offsetToUse,
          limit: itemsPerPage,
          search: searchKey,
          startDate: convertToISOString(range.startDate),
          endDate: convertToISOString(range.endDate),
        };
        let res = await getCustomerList(requestBody);

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          setList(res?.data?.data);
          setTotalCount(res?.data?.count);
        } else {
          (res?.message || res?.data?.message) &&
            toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    };
    customerList();
  }, [currentPage, itemsPerPage, searchKey, range, setSearchKey]);

  return (
    <>
      {/* <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
        <div></div>
        <div></div>
      </div>
      <div> */}
        {/* <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween"> */}
          <div className="mb_24">
            <h2 className="fontSize20 fontWeight600 blackClr_text">
              Customers List
            </h2>
          </div>
          {/* <div className="displayFlex justifyContent_spacebetween"> */}
            {/* <div className="pr_10"> <DatePicker range={range} setRange={setRange} /></div> */}
          {/* </div> */}
        {/* </div> */}

        {isLoading ? (
          <PaginatedTable
            row={list}
            column={column}
            totalCount={totalCount}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          isSearch={true}
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            maxPageNumberLimit={maxPageNumberLimit}
            setMaxPageNumberLimit={setMaxPageNumberLimit}
            minPageNumberLimit={minPageNumberLimit}
            setMinPageNumberLimit={setMinPageNumberLimit}
            setRange={setRange}
            range={range}
            isExport={true}
            isDateRange={true}
            handleExport={() => handleExport()}
          />
        ) : (
          <div style={{ textAlign: "center" }}>Loading...</div>
        )}
    </>
  );
};

export default Customerpage;
