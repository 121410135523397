import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getBrandList } from "../../services/super-admin.service";
import { getoffer } from "../../services/campaign.service";
import { selectCurrentUser } from "../../store/user/user.selector";
import toast from "react-hot-toast";
import AddIcon from "../../assets/images/add_icon.svg";
import ViewIcon from "../../assets/images/view_icon.svg";
import EditIcon from "../../assets/images/edit_icon.svg";
import { getCategorylist } from "../../services/Category.service";
import CreateCategorysetting from "./Create-Country";
import EditCategorysetting from "./Edit-Country";
import ViewCategorysetting   from "./View-Country";
import { getCountrylist } from "../../services/country.service";
import PaginatedTable from "../common/PaginatedTable";


const fileType = "Voucher";
function Country() {
  const [showCreate, setShowCreate] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [CategoryTable, setCategoryTable] = useState(true);
  const [data, setdata] = useState();
  const [Country, setCountry] = useState(null);
  // console.log("Category::: ", Category);
  const [brand, setBrand] = useState([]);
  const user = useSelector(selectCurrentUser)?.userDetail;

  const id = user?.userType === "brand" ? user?.brand_id : "";
  //Paginated Table Variables
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  const handleShowCreate = () => {
    setShowCreate(true);
    setCategoryTable(false);
  };

  const handleShowView = (country) => {
    setCategoryTable(false);
    setdata(country);
    setShowView(true);
  };



  const handleShowedit = (country) => {
    setdata(country);
    setShowEdit(true);
    setCategoryTable(false);
  };

  const getAllCountry = async () => {
    const offset = (currentPage - 1) * itemsPerPage;
    const offsetToUse = offset >= 0 ? offset : 0
    try {
      const requestBody = {
        offset: offsetToUse,
        limit: itemsPerPage,
        search: searchKey,
      };
      const resp = await getCountrylist(requestBody);
      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        console.log("resp::: oferr-------", resp);
        setCountry(resp?.data?.data);
        setTotalCount(resp?.data?.count);
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        console.error(msg);
      }
      console.log(error.message);
    }
  };

  



  const [column, setColumn] = useState([
    {
      heading: () => "Action",
      cell: (row) => (
        <>
        <div>
          <button onClick={() => handleShowView(row)} className="emptyBtn">
          <img
          src={ViewIcon}
          alt="ViewIcon"
          // onClick={() => handleShowedit(row)}
        />

          </button>
        </div>
        </>
      
      ),
      hidden: false,
      // width: "100",
      // align: "center",
      id: 1,
    },
    {
      heading: () => "Country Name",
      cell: (row) => (row["name"] ? row["name"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },
    {
      heading: () => "Currency Name ",
      cell: (row) => (row["currency_name"] ? row["currency_name"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },
    {
      heading: () => "Currency Code ",
      cell: (row) => (row["currency"] ? row["currency"]:"-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 4,
    },
    
    {
      heading: () => "Currency Symbols",
      cell: (row) => (row["currency_symbol"] ? row["currency_symbol"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 5,
    },
    {
      heading: () => "Mobile Code",
     
      cell: (row) => (row["dial"] ? row["dial"] : "-"),
      hidden: false,
      hidden: false,
      width: "100",
      id: 6,
    },

    {
      heading: () => "Status",
      cell: (row) => (
        <span
          className={
            row?.active
              ? "fontWeight600 UFOGreen_text"
              : "fontWeight600 errorClr_text"
          }
        >
          {row["active"] ? "Active" : "Inactive"}
        </span>
      ),
      hidden: false,
      width: "120",
      align: "left",
      id: 9,
    },
    
   
   
  ]);

  useEffect(() => {
    getAllCountry();
  }, [currentPage, itemsPerPage, searchKey,setSearchKey]);

  // const handleShowView = (country) => {
  //   setCategoryTable(false);
  //   setdata(country);
  //   setShowView(true);
  // };

  const back = (cond = true) => {
    cond && getAllCountry();
    setShowCreate(false);
    setShowEdit(false);
    setShowView(false);
    setCategoryTable(true);
  };

  return (
    <div>
      {CategoryTable && (
        <>
          <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
            <div>
              <h5 className="fontSize20 fontWeight600 blackClr_text">
                Country List
              </h5>
            </div>
            {/* <div>
              <button
                className="MainButton cyanSky_clr gap8"
                type="button"
                onClick={handleShowCreate}
              >
                <img src={AddIcon} alt="AddIcon" className="width20px" />
                <span> Create Category </span>
              </button>
            </div> */}
          </div>

    

          {isLoading ? (
            <PaginatedTable
              row={Country}
              column={column}
              totalCount={totalCount}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isSearch={true}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              maxPageNumberLimit={maxPageNumberLimit}
              setMaxPageNumberLimit={setMaxPageNumberLimit}
              minPageNumberLimit={minPageNumberLimit}
              setMinPageNumberLimit={setMinPageNumberLimit}
              //  setRange={setRange}
              //  range={range}
              isExport={false}
              isDateRange={false}
            />
          ) : (
            <div style={{ textAlign: "center" }}>Loading...</div>
          )}
        </>
      )}

      {showCreate && <CreateCategorysetting brand={data} back={back} />}
   
      {showView && <ViewCategorysetting data={data} back={back} />}
    </div>
  );
}

export default Country;
