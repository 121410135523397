import { KrtiPayService } from "./api";

const krtiWebUrl = process.env.REACT_APP_WEB_BASE_URL;

export const addBrandBank = async (req, id) => {
  try {
    return await KrtiPayService.post(
      `${krtiWebUrl}/bank-management/addBank/${id}`,
      req
    );
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};



export const bankMasterList = async (req) => {
  try {
    return await KrtiPayService.get(`${krtiWebUrl}/bank-management/bank-master/`, req);
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const getBrandBankList = async (id,requestBody) => {
  const { offset, limit, user_id, brand_id,search, startDate,endDate  } = requestBody
      try {
          return await KrtiPayService.get(`${krtiWebUrl}/bank-management/getBank/${id}/Merchant?offset=${offset}&limit=${limit}&search=${search}`)
      } catch (error) {
          console.log(error)
          return error?.response?.data
      }
  }
  

export const setPrimaryAccount = async (requestBody) => {
  const { id,account_id ,create_type} = requestBody
  try {
    return await KrtiPayService.post(
      `${krtiWebUrl}/bank-management/primary-card/`,
      requestBody,
      {}
    );
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};
export const removeBank = async (requestBody) => {
  // const { merchantId,accID } = requestBody
  const { id,account_id ,create_type} = requestBody
  try {
    return await KrtiPayService.post(
      `${krtiWebUrl}/bank-management/removeBankAccount`,
      requestBody,
      {}
    );
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
  
};

export const updatebankaccount = async (body) => {
  try {
      return await KrtiPayService.patch(`${krtiWebUrl}/bank-management/updateBankAccount`, body)
  } catch (error) {
      console.log(error)
      return error?.response?.data
  }
}

export const transaction  = async (requestBody) => {
  const { offset, limit, user_id, brand_id,search, startDate,endDate  } = requestBody
  

  try {

    return await KrtiPayService.get(
      `${krtiWebUrl}/transaction/get-master-transaction/${brand_id}?offset=${offset}&limit=${limit}&search=${search}&startDate=${startDate}&endDate=${endDate}`,
      requestBody,
      {}
    );
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
  
};
export const exportTransactions = async (requestBody) => {
  let { startDate, endDate, search } = requestBody
  try {
    window.open(`${krtiWebUrl}/transaction/transaction-excel?startDate=${startDate}&endDate=${endDate}&search=${search}`, '_blank');
    // return await KrtiPayService.get(`${krtiWebUrl}/transaction/transaction-excel`,);
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};


export const transactionbyid  = async (requestBody) => {
  const { offset, limit, user_id, brand_id,search,startDate ,endDate } = requestBody

  try {
    const requestBody = {
      startDate:startDate,
      endDate:endDate
    };

    return await KrtiPayService.get(
      `${krtiWebUrl}/transaction/get-master-transaction/${""}?offset=${offset}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&search=${search}`,
      requestBody,
      {}
    );
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
  
};


export const viewtransactionbyid  = async (requestBody) => {
  const { offset, limit, user_id, brand_id,search ,master_id } = requestBody

  try {
    // const requestBody = {
    //   user_id,
    //   brand_id,
    //   // search
    // };

    return await KrtiPayService.get(
      `${krtiWebUrl}/transaction/get-all-transaction/${master_id}?offset=${offset}&limit=${limit}`,
      requestBody,
      {}
    );
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
  
};
