import React, { useState } from "react";
import Select, { components } from "react-select";
// Multi Select input options and config
import CaretleftIcon from "../../assets/images/caretleft_icon.svg";
import CaretRightIcon from "../../assets/images/caretRight_icon.svg";
import SearchIcon from "../../assets/images/search_icon.svg";
import ExcelIcon from "../../assets/images/excel_icon.svg";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import CloseIcon from "../../assets/images/close_icon.svg";
import SelectdateIcon from "../../assets/images/selectdate_icon.svg";
import FilterIcon from "../../assets/images/filter_icon.svg";



function PaginatedTable({
  column = [],
  row = [],
  totalCount = 0,
  itemsPerPage,
  setItemsPerPage,
  isSearch = false,
  currentPage,
  setCurrentPage,
  searchKey,
  setSearchKey,
  isExport = false,
  handleExport = () => null,
  maxPageNumberLimit = 5,
  setMaxPageNumberLimit,
  minPageNumberLimit = 0,
  setMinPageNumberLimit,
  isvisible = true,
  isDateRange = false,
  setRange,
  range,
  isfilter = false,
  statusFilter,
  setStatusFilter,
}) {
  //pagintation related function starts here
  const [pageNumberLimit] = useState(3);

  const [timer, setTimer] = useState(null);
  let pages = [];

  //create pages based on total count
  for (let i = 1; i <= Math.ceil(totalCount / itemsPerPage); i++) {
    pages.push(i);
  }

  const handleClick = (event) => {
    console.log(event);
    setCurrentPage(Number(event.target.id));
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrev = () => {
    setCurrentPage((prev) => prev - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageDecrementBtn = null;
  if (minPageNumberLimit !== 0) {
    pageDecrementBtn = (
      <li onClick={handlePrev}>
        <button>&hellip;</button>
      </li>
    );
  }

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = (
      <li onClick={handleNext}>
        <button>&hellip;</button>
      </li>
    );
  }

  let start = (currentPage - 1) * itemsPerPage + 1;
  // console.log(start)
  let end =
    totalCount < currentPage * itemsPerPage
      ? totalCount
      : currentPage * itemsPerPage;

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage === number ? "active" : null}
        >
          <button id={number}> {number}</button>
        </li>
      );
    } else {
      return <></>;
    }
  });
  //pagination related function ends here

  //handle search with delayed trigger - mannual debounce
  const handleSearch = (searchValue) => {
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      setSearchKey(searchValue);
      setCurrentPage(1);
      setMaxPageNumberLimit(pageNumberLimit);
      setMinPageNumberLimit(0);
    }, 1000);

    setTimer(newTimer);
  };
  ///daterange //
  const [isOpen, setIsOpen] = useState(false);
  const handleTogglePicker = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (ranges) => {
    console.log(ranges["range1"]);
    const temp = {
      startDate: ranges["range1"]["startDate"],
      endDate: ranges["range1"]["endDate"],
    };
    setRange(temp);
  };

  //filter option
  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(1);
    setMaxPageNumberLimit(pageNumberLimit);
    setMinPageNumberLimit(0);
  };

  // const [statusFilter, setStatusFilter] = useState("");

  return (
    <>
      <div className="">
        {/* Search */}
        <div className="displayFlex alignItem_center justifyContent_spacebetween mb_24 Mob_flexWrapGap">
          {/* Select entries per page */}
          {isvisible ? (
            <div className="displayFlex alignItem_center gap12">
              <div>
                {" "}
                <p className="fontSize14 fontWeight500 SteelWoolClr_text">
                  Show
                </p>
              </div>
              <div>
                {" "}
                <select
                  className="selectMainInput height36px"
                  defaultValue={itemsPerPage}
                  onChange={(e) => {
                    setItemsPerPage(Number(e.target.value));
                    setCurrentPage(1);
                    setMaxPageNumberLimit(pageNumberLimit);
                    setMinPageNumberLimit(0);
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
              <div>
                <p className="fontSize14 fontWeight500 SteelWoolClr_text">
                  Entries
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="displayFlex alignItem_center gap12 Mob_flexWrapGap">
            {" "}
            {isSearch ? (
              <div className="Groupinput whiteBg borderRadius10">
                <input
                  type="text"
                  placeholder="Search"
                  style={{ outline: "none" }}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                />
                <div className="inputgroup_append">
                  <span className="Groupinput_text">
                    <button type="button" className="emptyBtn">
                      <img
                        src={SearchIcon}
                        className="width20px"
                        alt="SearchIcon"
                      />
                    </button>
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* Export Button */}
            {isExport ? (
              <div>
                {" "}
                <button
                  className="MainButton crusoe_clr gap12"
                  onClick={() => handleExport()}
                >
                  <img className="width18px" src={ExcelIcon} alt="ExcelIcon" />
                  <span>Export</span>
                </button>{" "}
              </div>
            ) : (
              <></>
            )}
            <div className="position_relative">
            {/* Date*/}
            {isDateRange ? (
              <>
                <button
                  className="lemonPie_clr MainButton gap12"
                  onClick={handleTogglePicker}
                >
                  <img className="width20px" src={SelectdateIcon} />
                  <span>Select date</span>
                </button>
              </>
            ) : (
              <></>
            )}
            {isOpen && (
                <div className="DateRangePickerPosition">
                <DateRangePicker ranges={[range]} onChange={handleSelect} />
                <button
                  className="closeDateRangePicker"
                  onClick={handleTogglePicker}
                >
                  <img src={CloseIcon} alt="CloseIcon" />
                </button>
                </div>
            )}
            </div>
            {isfilter ? (
              <div>

                  <div className="grayGroupinput">
                    <div className="inputgroup_append">
                    <span className="grayGroupinput_text">
                        <button type="button" className="emptyBtn">
                        <img src={FilterIcon} alt="FilterIcon" className="width20px black_filter" />
                        
                        </button>
                      </span>
                    </div>
                    <select
                      value={statusFilter}
                      onChange={handleStatusFilterChange}
                    >
                      <option value="all">All</option>
                      <option value="open">Open</option>
                      <option value="onprogress">On Progress</option>
                      <option value="completed">Completed</option>
                    </select>
              </div>






                  {/* <p className="fontSize14 fontWeight500 SteelWoolClr_text">
                    Status
                  </p> */}

              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {/* Table Section - Auto Genration of Rows and columns */}
      <div className="Table_Wrapper tableResponsive">
        <table className="mb_24">
          <thead>
            <tr>
              {column.map((e, i) => {
                return (
                  <th
                    style={{ minWidth: `${e.width}px` }}
                    hidden={e?.hidden}
                    key={e?.id}
                  >
                    {e?.heading()}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {row?.length > 0 ? (
              row?.map((rowData, i) => {
                return (
                  <tr key={i}>
                    {column?.map((columnData, j) => {
                      return (
                        <td
                          style={{
                            minWidth: `${columnData?.width}px`,
                            textAlign: columnData.align,
                          }}
                          key={j}
                          hidden={columnData?.hidden}
                        >
                          {columnData?.cell(rowData, start + i)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td style={{ textAlign: "center" }} colSpan={column.length}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Pagination Section */}
      {row?.length > 0 ? (
        <div className="displayFlex alignItem_center justifyContent_spacebetween flexWrap_wrap gap12 mt_24">
          <div>
            <p className="fontSize14 fontWeight500 SteelWoolClr_text">
              {" "}
              Showing {start} to {end} of {totalCount} entries
            </p>
          </div>

          <div className="custom_pagination">
            <ul>
              <li>
                <button onClick={handlePrev} disabled={currentPage === 1}>
                  <img src={CaretleftIcon} alt="CaretleftIcon" />
                </button>
              </li>
              {pageDecrementBtn}
              {renderPageNumbers}
              {pageIncrementBtn}
              <li>
                <button
                  onClick={handleNext}
                  disabled={currentPage === pages.length}
                >
                  <img src={CaretRightIcon} alt="CaretRightIcon" />
                </button>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <> </>
      )}
    </>
  );
}

export default PaginatedTable;
