import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';

import { getDropDownList } from "../../services/super-admin.service";
import { getoffer } from "../../services/campaign.service";
import CreateCampaign from "./CreateVoucher";
import EditCampaign from "./EditVoucher";
import { selectCurrentUser } from "../../store/user/user.selector";
import toast from "react-hot-toast";
import AddIcon from "../../assets/images/add_icon.svg";


import ViewIcon from "../../assets/images/view_icon.svg";
import EditIcon from "../../assets/images/edit_icon.svg";
import { getVoucher } from "../../services/voucher.service";
import CreateVoucher from "./CreateVoucher";
import EditVoucher from "./EditVoucher";
import ViewVoucher from "./ViewVoucher";
import PaginatedTable from "../common/PaginatedTable";
import { formatCurrency } from '../../utils/helpers/helper'
import DatePicker from "../DatePicker/DatePicker";
import { exportVoucher } from "../../services/export.service";
import { convertToISOString } from "../../services/common.service";
import { useNavigate } from 'react-router-dom';


function Voucher() {

  const [showCreate, setShowCreate] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [voucherTable, setVoucherTable] = useState(true);
  const [data, setdata] = useState();
  const [vouchers, setVouchers] = useState(null);
  const [brand, setBrand] = useState([]);
  const user = useSelector(selectCurrentUser)?.userDetail;
  const navigate = useNavigate();
  const id = user?.userType === 'brand' ? user?.brand_id : ''

  //Paginated Table Variables
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  const [range, setRange] = useState({
    startDate: undefined,
    endDate: new Date(),
  });

  const handleExport = async () => {
    try {
      let payload = {
        startDate: convertToISOString(range.startDate),
        endDate: convertToISOString(range.endDate),
        search: searchKey
      }
      await exportVoucher(payload)
    } catch (error) {

    }
  }

  const handleShowCreate = () => {
    setShowCreate(true);
    setVoucherTable(false);
  }

  const handleShowedit = (data) => {
    setdata(data);
    setShowEdit(true);
    setVoucherTable(false)
  }


  const getAllVoucher = async () => {
    const offset = (currentPage - 1) * itemsPerPage;
    const offsetToUse = offset >= 0 ? offset : 0
    try {
      const requestBody = {
        offset: offsetToUse,
        limit: itemsPerPage,
        search: searchKey,
        startDate: convertToISOString(range.startDate),
        endDate: convertToISOString(range.endDate)
      };
      const resp = await getVoucher(id, requestBody);
      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
     
        setVouchers(resp?.data?.data);
        setTotalCount(resp?.data?.count);

      } else {
        toast.error(resp?.message || resp?.data?.message);
      }

    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        console.error(msg)

      }
      console.log(error.message);
    }
  };



  const [column, setColumn] = useState([
    {
      heading: () => "Action",
      cell: (row) => (
        <div>
          {/* <button className="emptyBtn" onClick={() => handleShowedit(row)}>
            <img className="CyanSky_filter" src={EditIcon} alt="EditIcon" />
          </button> */}
          <button className="emptyBtn" onClick={() => handleShowView(row)}>
            <img src={ViewIcon} alt="ViewIcon" />
          </button>
        </div>
      ),

      hidden: false,
      // width: "120",
      // align: "left",
      id: 1,
    },
    {
      heading: () => "Voucher Name",
      cell: (row) => (
        <div className="textWrap_Wrap">
          {row["campaign_name"] ? row["campaign_name"] : "-"}
        </div>
      ),
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },
    // row["brand_name"] === " " ? "-" : row["brand_name"]
    {
      heading: () => "Merchant Name",
      cell: (row) => (<div>
        <a
           className="emptyBtn cursorPointer blackClr_text"
          onClick={() => navigate(`/admin/brand-detail/${row?.brand_id}`)}
        >
          {row["brand_name"]}
        </a>
      </div>),
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },
    {
      heading: () => "Start Date",

      cell: (row) => (row["start_Date"] ? row["start_Date"] : "-"),
      hidden: false,
      hidden: false,
      width: "100",
      id: 4,
    },
    {
      heading: () => "End Date",
      cell: (row) => (row["end_Date"] ? row["end_Date"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 5,
    },
    {
      heading: () => "Type",
      cell: (row) => (row["offer_type"] ? row["offer_type"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 6,
    },
    {
      heading: () => "Value",
      cell: (offer) => {
        if (offer?.offer_type === 'Value') {
          return <>  {formatCurrency(offer?.offer_value)}</>;
        } else if (offer?.offer_type === 'Percentage') {
          return <>{offer?.offer_value}%</>;
        } else {
          return <>-</>; // Default value if offer_type is neither 'Value' nor 'Percentage'
        }
      },
      hidden: false,
      width: "120",
      align: "left",
      id: 7,
    },
    {
      heading: () => "Status",
      cell: (row) => (
        <span className={row?.active_flag ? "fontWeight600 UFOGreen_text" : "fontWeight600 errorClr_text"}>
          {row["active_flag"] ? "Active" : "Inactive"}
        </span>
      ),
      hidden: false,
      width: "120",
      align: "left",
      id: 8,
    },

  ]);



  useEffect(() => {

    getAllVoucher()
    const getBrand = async () => {
      const offset = (currentPage - 1) * itemsPerPage;
      const offsetToUse = offset >= 0 ? offset : 0
      try {
        const requestBody = {
          offset: offsetToUse,
          limit: itemsPerPage,
          search: searchKey,
        };
        const res = await getDropDownList(requestBody);
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          setBrand(res?.data?.getDropDownList || []);
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg)
        }
        console.log(error.message);

      }

    }
    getBrand();

  }, [currentPage, itemsPerPage, searchKey, setSearchKey, range]);

  const handleShowView = (offer) => {
    setVoucherTable(false)
    setdata(offer);
    setShowView(true);
  };

  const back = (cond = true) => {
    cond && getAllVoucher()
    setShowCreate(false);
    setShowEdit(false);
    setShowView(false);
    setVoucherTable(true)

  }

  return (
    <div>
      {
        voucherTable &&
        <>
          <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
            <div>
              <h5 className="fontSize20 fontWeight600 blackClr_text">Vouchers List</h5>
            </div>
            <div className="displayFlex justifyContent_spacebetween">
              {/* <div className="pr_10"> <DatePicker range={range} setRange={setRange} /></div> */}
              <button
                className="MainButton cyanSky_clr gap8"
                type="button"
                onClick={handleShowCreate}
              >
                <img src={AddIcon} alt='AddIcon' className='width20px' />
                <span> Create Voucher </span>
              </button>
            </div>
          </div>



          {isLoading ? (
            <PaginatedTable
              row={vouchers}
              column={column}
              totalCount={totalCount}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isSearch={true}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              maxPageNumberLimit={maxPageNumberLimit}
              setMaxPageNumberLimit={setMaxPageNumberLimit}
              minPageNumberLimit={minPageNumberLimit}
              setMinPageNumberLimit={setMinPageNumberLimit}
              setRange={setRange}
              range={range}
              isExport={true}
              isDateRange={true}
              handleExport={() => handleExport()}
            />
          ) : (
            <div style={{ textAlign: "center" }}>Loading...</div>
          )}
        </>
      }

      {showCreate && <CreateVoucher brand={brand} back={back} />}

      {showView && <ViewVoucher voucher={data} back={back} brand={brand} handleShowedit={handleShowedit} />}

    </div>
  );
}

export default Voucher;