import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Transactions from "../branding/transcation";
import BankDetails from "../branding/bank-details";
import CustomerBankDetails from "./CustomerBankDetails";
import BackIcon from "../../assets/images/back_icon.svg";
import TransactionsIcon from "../../assets/images/transactions_icon.svg";
import BanckAccIcon from "../../assets/images/banckAcc_icon.svg";
import BrandDetailsIcon from "../../assets/images/brandDetails_icon.svg";
import BanklistIcon from "../../assets/images/banklist_icon.svg";
import AddBankIcon from "../../assets/images/addBank_icon.svg";
import EditIcon from "../../assets/images/edit_icon.svg";
import creditcardIcon from "../../assets/images/creditCard_icon.svg";

import Editcashbackrule from "../branding/Editcashbackrule";
import ViewCustomerDetail from "./ViewCustomerDetails";
import VoucherIcon from "../../assets/images/voucher_icon.svg";
import CustomerWallet from "./CustomerWallet";
import CustomerVoucher from "./CustomerVoucher";
import CustomerTransactions from "./CustomerTransactions";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import { getUserWalletDetails } from "../../services/customer.service";
import { formatCurrency } from "../../utils/helpers/helper";
import toast from "react-hot-toast";
import CustomerCard from "./Customercard";
const CustomerDetails = () => {
  const navigate = useNavigate();
  const { id, customer_name } = useParams();
  const tabs = {
    detail: "Customer Details",
    CustomerTransactions: "Transaction",
    CustomerBankDetails: "Bank Accounts",
    customerwallet: "Customer Wallet",
    CustomerVoucher: "Vouchers",
    CustomerCard: "Card",

  };

  const [activetab, setActiveTab] = useState(tabs.detail);
  const [addBank, setAddBank] = useState(false);
  const [walletData, setWalletData] = useState(null);
  console.log("walletData::: ", walletData);

  const getWalletData = async (id) => {
    try {
      const res = await getUserWalletDetails(id);
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        setWalletData(res?.data?.data);
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      console.error("Error fetching wallet data:", error.message);
      toast.error("Failed to fetch wallet data");
    }
  };

  const walletAmount = walletData ? walletData.wallet_amount : null;

  useEffect(() => {
    getWalletData(id);
    console.log(customer_name);
  }, [customer_name, id]);

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <div>
      <h2 className="fontSize20 blackClr_text mb_16">
        <span className="fontWeight400">Customer Name - </span>
        <span className="fontWeight500">{customer_name}</span>
      </h2>
      <ul className="horizontalTablist">
        <li>
          <button
            onClick={() => setActiveTab(tabs.detail)}
            className={activetab === tabs.detail ? "active" : ""}
          >
            <img src={BrandDetailsIcon} alt="BrandDetailsIcon" />
            Customer Details
          </button>
        </li>
        <li>
          <button
            onClick={() => setActiveTab(tabs.CustomerBankDetails)}
            className={activetab === tabs.CustomerBankDetails ? "active" : ""}
          >
            <img src={BanckAccIcon} alt="BanckAccIcon" />
            <span>Bank Account</span>
          </button>
        </li>
        <li>
          <button
            onClick={() => setActiveTab(tabs.CustomerTransactions)}
            className={activetab === tabs.CustomerTransactions ? "active" : ""}
          >
            <img src={TransactionsIcon} alt="TransactionsIcon" />
            <span>Transaction</span>
          </button>
        </li>
        <li>
          <button
            onClick={() => setActiveTab(tabs.customerwallet)}
            className={activetab === tabs.customerwallet ? "active" : ""}
          >
            <img src={BrandDetailsIcon} alt="Customer Wallet" />
            <span>Customer Wallet</span>
          </button>
        </li>
        <li>
          <button
            onClick={() => setActiveTab(tabs.CustomerVoucher)}
            className={activetab === tabs.CustomerVoucher ? "active" : ""}
          >
            <img src={VoucherIcon} alt="VoucherIcon" />
            <span>Vouchers</span>
          </button>
        </li>
        <li>
          <button
            onClick={() => setActiveTab(tabs.CustomerCard)}
            className={activetab === tabs.CustomerCard ? "active" : ""}
          >
            <img src={creditcardIcon} alt="creditcardIcon" />
            <span>Cards</span>
          </button>
        </li>
      </ul>
      <br />

      <div className="whiteFrame">
        <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween BorderBottom_LavenderSyrup pb_16">
          <div className="displayFlex alignItem_center gap12">
            <div>
              <button className="emptyBtn" onClick={handleClick}>
                <img src={ArrowleftIcon} alt="ArrowleftIcon" />
              </button>
            </div>
            <div>
              <h5 className="fontSize20 fontWeight600 blackClr_text">
                {activetab}
              </h5>
            </div>
          </div>
          <div className="displayFlex alignItem_center gap12">
            {activetab === tabs.customerwallet && (
              <div className={`walletAmountFrame ${"active"}`}>
                Wallet Amount: {formatCurrency(walletAmount)}
              </div>
            )}

            {/* {tabs.CustomerBankDetails === activetab && (
              <div>
                <button
                  className="MainButton lemonPie_clr gap8"
                  onClick={() => setAddBank(!addBank)}
                >
                  {addBank ? (
                    <img src={BanklistIcon} alt="BanklistIcon" />
                  ) : (
                    <img src={AddBankIcon} alt="AddBankIcon" />
                  )}
                  {addBank ? "Bank List" : "Add bank details"}
                </button>
              </div>
            )} */}

            {/* <div>
                {activetab === tabs.detail  &&(
                <button
                  className="MainButton cyanSky_clr gap8"
                  type="button"
                  onClick={() => setActiveTab(tabs.edit)}
                >
                  <img className='width20px' src={EditIcon} alt='EditIcon' />
                  <span> Edit </span>
                </button>
                )}
              </div> */}
            <div>
              {activetab === tabs.Edit_cash_rule && (
                <button
                  className="MainButton cyanSky_clr gap8"
                  type="button"
                  onClick={() => setActiveTab(tabs.Edit_cash_rule)}
                >
                  <img className="width20px" src={EditIcon} alt="EditIcon" />
                  <span> Edit </span>
                </button>
              )}
            </div>
            {/* <div>
              <button
                className="MainButton cyanSky_clr gap8"
                type="button"
                onClick={handleClick}
              >
                <img src={BackIcon} alt="BackIcon" />
                <span> Back </span>
              </button>
            </div> */}
          </div>
        </div>
        {activetab === tabs.detail && <ViewCustomerDetail />}
        {activetab === tabs.CustomerTransactions && <CustomerTransactions />}
        {activetab === tabs.customerwallet && <CustomerWallet />}
        {activetab === tabs.CustomerCard && <CustomerCard />}

        {activetab === tabs.CustomerVoucher && <CustomerVoucher />}
        {activetab === tabs.CustomerBankDetails && (
          <CustomerBankDetails addBank={addBank} setAddBank={setAddBank} />
        )}
        {activetab === tabs.edit && (
          <Editcashbackrule back={() => setActiveTab(tabs.Edit_cash_rule)} />
        )}
      </div>
    </div>
  );
};

export default CustomerDetails;
