import { KrtiPayService } from './api'


const krtiWebUrl = process.env.REACT_APP_WEB_BASE_URL;


export const getVoucher = async (id, requestBody) => {
    const { offset, limit, user_id, brand_id, search, startDate, endDate } = requestBody
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/voucher-management?id=${id}&offset=${offset}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&search=${search}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const CreateNewVoucher = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/voucher-management/add-voucher
        `, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const EditVoucherDetail = async (req) => {
    try {
        return await KrtiPayService.patch(`${krtiWebUrl}/voucher-management/update-voucher
        `, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const updateVoucherStatus = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/voucher-management/active-voucher/${req?.id}/${req?.status}
        `)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const deleteVoucherById = async (req) => {
    try {
        return await KrtiPayService.delete(`${krtiWebUrl}/voucher-management/${req?.id}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const getVoucherlist = async (payload) => {
      const { offset, limit, id, brand_id,search,startDate ,endDate } = payload
      
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/voucher-management/get-vouchers/${id}?offset=${offset}&limit=${limit}`,payload)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const GetVoucherSetting = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/voucher-management/voucher-setting`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const UpdateVoucherSetting = async (req) => {
    try {
        return await KrtiPayService.post(`${krtiWebUrl}/voucher-management/voucher-setting`, req)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

